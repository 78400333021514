import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, Button, CssBaseline, Drawer } from '@mui/material';
import Menu from '../components/Menu';
import { getToken, removeToken } from '../utils/token';
import { validateToken } from '../services/auth';
import sizeConfigs from "../configs/sizeConfigs";
import colorConfigs from "../configs/colorConfigs";

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        await validateToken(token);
      } catch (error) {
        removeToken();
        navigate('/login');
      }
    };

    checkToken();
  }, [navigate]);

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: sizeConfigs.sidebar.widthN,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: sizeConfigs.sidebar.widthN, boxSizing: 'border-box' },
        }}
      >
        <Menu open={open} toggleDrawer={() => setOpen(!open)}/>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: `10px`,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            width: `calc(100% - ${sizeConfigs.sidebar.widthN}px)`,
            marginLeft: `${sizeConfigs.sidebar.widthN}px`,
            bgcolor : colorConfigs.sidebar.bg,
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Link Short
            </Typography>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Necessário para compensar a altura do AppBar */}
        <Box sx={{ flexGrow: 1, p: 1, mt: 1}}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
