import React, { useState } from 'react';
import { getLink } from '../services/auth';
import { Typography, Box, TextField, Button, List, ListItem, ListItemText } from '@mui/material';

export interface IMetadado {
  id: number;
  iD_Link: number;
  tipo_Link?: string;
  canal_Envio?: number;
}

export interface ILink {
  id: number;
  url: string;
  shortUrl?: string;
  clicks?: number;
  metadado?: IMetadado;
}

const LinkSearch: React.FC = () => {
  const [shortURL, setShortURL] = useState('');
  const [links, setLinks] = useState<ILink[]>([]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const data = await getLink(shortURL);
      
      setLinks(data);
      console.log(links);
    } catch (error) {
      console.error('Erro ao pesquisa Links:', error);
    }
  };

  return (
    <>
    <Box sx={{ p: 1 }}>
      <Typography variant="h4">Pesquisa de Links</Typography>
      <Box>
        <TextField
          label="Link encurtado"
          value={shortURL}
          onChange={(e) => setShortURL(e.target.value)}
          margin="normal"
          fullWidth
        />
        <Box>
          <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginTop: '16px' }}>
            Buscar
          </Button>
        </Box>
        <List>
          {links.map((link) => (
            <ListItem key={link.id}>
              <ListItemText
                primary={`URL: ${link.url}`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {`Short URL: ${link.shortUrl}`}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="textPrimary">
                      {`Clicks: ${link.clicks}`}
                    </Typography>
                    <br />
                    {link.metadado && (
                      <>
                        <Typography component="span" variant="body2" color="textPrimary">
                          {`Tipo Link: ${link.metadado.tipo_Link == undefined ? '' : link.metadado.tipo_Link }`}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="textPrimary">
                          {`Canal Envio: ${link.metadado.canal_Envio == undefined ? '' : link.metadado.canal_Envio == 0 ? 'Email' : 'SMS'}`}
                        </Typography>
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      </Box>
    </>
  );
};

export default LinkSearch;