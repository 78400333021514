import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getToken, removeToken } from '../utils/token';
import { validateToken } from '../services/auth';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = React.useState<boolean | null>(null);

  useEffect(() => {
    const checkToken = async () => {
      const token = getToken();
      if (!token) {
        setIsTokenValid(false);
        return;
      }
      try {
        await validateToken(token);
        setIsTokenValid(true);
      } catch (error) {
        removeToken();
        setIsTokenValid(false);
      }
    };

    checkToken();
  }, []);

  if (isTokenValid === null) {
    // Você pode exibir um loader aqui enquanto verifica o token
    return <div>Loading...</div>;
  }

  return isTokenValid ? <>{children}</> : <Navigate to="/login" />;
};

export default ProtectedRoute;