import React, { useState } from 'react';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Typography, Grid,Tooltip, IconButton } from '@mui/material';
import { createLink } from '../services/auth';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const LinkForm: React.FC = () => {
  const [url, setUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [tipoLink, setTipoLink] = useState('');
  const [canalEnvio, setCanalEnvio] = useState<number | string>('');
  const [responseLink, setResponseLink] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const linkData = {
      Url: url,
      ShortUrl: shortUrl,
      Metado: {
        Tipo_Link: tipoLink,
        Canal_Envio: canalEnvio,
      }
    };

    try {
      const response = await createLink(linkData); // Substitua pela URL real da sua API
      setResponseLink(response.result); // Assumindo que a API retorna o link completo em um campo chamado "completeLink"
    } catch (error) {
      console.error('Erro ao chamar a API:', error);
      setResponseLink(null);
    }
  };

  const handleCopyToClipboard = (link : string | undefined) => {
    if (link) {
        navigator.clipboard.writeText(link);
    }
};

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Criar Link</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="ShortURL"
              value={shortUrl}
              onChange={(e) => setShortUrl(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Tipo Link"
              value={tipoLink}
              onChange={(e) => setTipoLink(e.target.value)}
              fullWidth
              margin="normal"   
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="canal-envio-label">Canal de Envio</InputLabel>
              <Select
                labelId="canal-envio-label"
                value={canalEnvio}
                onChange={(e) => setCanalEnvio(e.target.value)}
              >
                <MenuItem value={0}>Email</MenuItem>
                <MenuItem value={1}>SMS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Criar Link
        </Button>
      </form>
      {responseLink && (
        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ mr: 2 }}>Link Completo Gerado:</Typography>
        <Typography variant="body1" sx={{ mr: 2 }}>{responseLink}</Typography>
        <Tooltip title="Copiar para Área de Transferência">
          <IconButton color="primary" onClick={() => handleCopyToClipboard(responseLink)}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
      )}
    </Box>
  );
};

export default LinkForm;
