import api from './api';

export const login = async (email: string, password: string) => {
  const response = await api.post('/api/Login/login', { email, password });
  console.log(response);
  return response.data;
};

export const validateToken = async (token: string) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  const response = await api.get('/api/Login/validartoken');
  return response.data;
};

export const getLink = async (shorturl: string) => {
  const token = localStorage.getItem("token");
  api.defaults.headers.Authorization = `Bearer ${token}`;

  const response = await api.get(`/api/Links/GetByShortURL/${shorturl}`);
  return response.data;
};

export const createLink = async (linkData: any) => {
  const response = await api.post(`/api/Links?key=76d5acd47707ecb82e9a579ddbe5e70b80e82e67df4eb526546f4f065a3b6`, linkData);
  return response.data;
};