import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Link, Snackbar, CssBaseline, 
  Alert, TextField, CircularProgress, Button, Paper } from '@mui/material';
import { login } from '../services/auth';
import { setToken } from '../utils/token';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export function Copyright(props: any) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
          {'© '}
          {new Date().getFullYear()}
          {' Guarida Imóveis. Todos os direitos reservados. '}
          <Link color="inherit" href="https://app.gua.rs/">
              LinkShort - Guarida
          </Link>{' '}
          {'.'}
      </Typography>
  );
}

const theme = createTheme();

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msgAlert, setMsgAlert] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
};

  const imageUrls = [
    'https://storage.googleapis.com/storage_geral/imagens/logo/1.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/2.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/3.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/4.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/5.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/6.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/7.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/8.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/9.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/10.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/11.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/12.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/13.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/14.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/15.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/16.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/17.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/18.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/19.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/20.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/21.jpg',
    'https://storage.googleapis.com/storage_geral/imagens/logo/22.jpg'
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    setBackgroundImage(imageUrls[randomIndex]);
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = await login(email, password);
      setToken(data.token);
      setLoading(false);
      window.location.href = '/links';
    } catch (error) {
        setLoading(false);
        console.error('Erro ao fazer login:', error);
        setMsgAlert('Erro ao fazer login: ' + error);
        setSeverity('error');
        setOpen(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                      backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="https://storage.googleapis.com/storage_geral/imagens/logo/logo.png"
                            alt="Logo"
                            style={{ width: '50%', height: 'auto' }}>
                        </img>

                        <Typography component="h1" variant="h5">
                            LinkShort - Login
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            {loading ? (<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                                <CircularProgress size={24} />
                            </Box>) : (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Entrar
                                </Button>)}
                        </Box>
                        <Grid container>
                        </Grid>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
  );
};

export default Login;