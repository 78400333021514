import { Drawer, List, Stack, Toolbar, } from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import SidebarItem from "./SidebarItem";
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode } from "react";
import UserSearch from '../pages/UserSearch';
import LinkForm from '../pages/LinkForm';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';

const assets = {
  images: {
        logo: require("../images/logo_branco.png"),
        logo_g_branco: require("../images/logo_g_branco.png")
  }
};

interface SidebarProps {
  open: boolean;
  toggleDrawer: () => void;
}

export type RouteType = {
  element: ReactNode,
  state: string,
  index?: boolean,
  path?: string,
  child?: RouteType[],
  sidebarProps?: {
    displayText: string,
    icon?: ReactNode;
  };
};

function retornoArray() {
  const appRoutesV2: RouteType[] = [];

  appRoutesV2.push({
    path: "/links",
    state: "links",
    element: <UserSearch />,
    sidebarProps: {
        displayText: "Links",
        icon: <SearchIcon/>
    }, child: []});

  /*appRoutesV2.push({
    path: "/user-search",
    state: "user-search",
    element: <UserSearch />,
    sidebarProps: {
        displayText: "Usuarios",
        icon: <PeopleIcon />
    }, child: []});
*/
  appRoutesV2.push({
    path: "/link-form",
    state: "link-form",
    element: <LinkForm />,
    sidebarProps: {
        displayText: "Criar Link",
        icon: <AddLinkIcon/>
    }, child: []});

  return appRoutesV2;
}

const Menu: React.FC<SidebarProps> = ({ open, toggleDrawer }) => {

  const appRoutes: RouteType[] = retornoArray();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "80px",
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          ...(open && {
            width: sizeConfigs.sidebar.width,
            transition: "width 0.5s ease-in-out"
          })
        }
      }}
    >

        <List disablePadding>
          <Toolbar component={RouterLink} to="/" sx={{ marginBottom: "20px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <img src={assets.images.logo} alt='logo' />
            </Stack>
          </Toolbar>
          {appRoutes.map((route, index) => (
            <SidebarItem item={route} key={index} />
          ))}
        </List>
      
    </Drawer>
  );
};

export default Menu;