import React from 'react';
import UserSearchForm from '../components/UserSearchForm';
import { Typography, Box } from '@mui/material';

const UserSearch: React.FC = () => {
  return (
    <div>
      <Box sx={{ p: 1 }}>
      <Typography variant="h4">Pesquisa de Usuário</Typography>
      <UserSearchForm />
      </Box>
    </div>
  );
};

export default UserSearch;