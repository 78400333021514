import React, { useState } from 'react';
import { Button, TextField, Box, List, ListItem, ListItemText } from '@mui/material';
import api from '../services/api';

const UserSearchForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await api.get(`/users?email=${email}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuário:', error);
    }
  };

  return (
    <Box>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          fullWidth
        />
        <Box>
          <Button onClick={handleSearch} variant="contained" color="primary" sx={{ marginTop: '16px' }}>
            Buscar
          </Button>
        </Box>
      <List>
      </List>
    </Box>
  );
};

export default UserSearchForm;